import { NextPage } from 'next';
import React from 'react';
import { Heading, Text } from '@petrus/ui-library';
const Home: NextPage = () => <div className="w-screen h-screen bg-blush p-8">
    <Heading as="h1" className="text-carmine font-bold">
      Home
    </Heading>
    <Text as="span" className="text-carmine">
      {process.env.NODE_ENV}
    </Text>
  </div>;
export default Home;